import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

import Header from "../components/Header";

import {
  IPrismicHeader,
  IPrismicFooter,
  IPrismicBookDemo,
} from "../scenes/prismic.model";
import Footer from "../components/Footer";
import { FAVICON } from "../utils/constants";
import BookDemo from "../scenes/BookDemo";

interface IProps {
  data: {
    prismicHeader: {
      data: IPrismicHeader;
    };
    prismicBookDemo: {
      data: IPrismicBookDemo;
    };
    prismicFooter: {
      data: IPrismicFooter;
    };
  };
  pageContext: {
    locale: string;
    name: string;
  };
}

export default function BookDemoPage(props: IProps) {
  return (
    <>
      <Helmet>
        <title>matchHR</title>
        <link rel="shortcut icon" href={FAVICON} type="image/x-icon" />
      </Helmet>
      <Header
        data={props.data.prismicHeader.data}
        pageContext={props.pageContext}
      />
      <BookDemo
        data={props.data.prismicBookDemo.data}
        pageContext={props.pageContext}
      />
      <Footer data={props.data.prismicFooter.data} />
    </>
  );
}

export const pageQuery = graphql`
  query prismicBookDemoQuery($locale: String!) {
    prismicHeader(lang: { eq: $locale }) {
      data {
        how_it_works_link {
          text
        }
        pricing_link {
          text
        }
        contact_us_link {
          text
        }
        about_us_link {
          text
        }
        login_link {
          text
        }
        signup_link {
          text
        }
        book_demo_link {
          text
        }
        get_started_link {
          text
        }
      }
    }
    prismicBookDemo(lang: { eq: $locale }) {
      data {
        header_image {
          url
        }
        title {
          text
        }
        description {
          text
        }
        first_name_label {
          text
        }
        last_name_label {
          text
        }
        email_label {
          text
        }
        phone_number_label {
          text
        }
        reason_label {
          text
        }
        hires_label {
          text
        }
        message_label {
          text
        }
        message_subtitle {
          text
        }
        submit_button {
          text
        }
        success_title {
          text
        }
        success_description {
          text
        }
        success_button {
          text
        }
        error_title {
          text
        }
        error_description {
          text
        }
        error_button {
          text
        }
      }
    }
    prismicFooter(lang: { eq: $locale }) {
      data {
        footer_heading {
          text
        }
        footer_label {
          text
        }
        get_started_button {
          text
        }
        copyright {
          text
        }
        contact_us_link {
          text
        }
        privacy_policy_link {
          text
        }
      }
    }
  }
`;
