import React, { Component, ComponentState } from "react";
import { appInsights } from "../../components/AppInsights/AppInsights";
import LocalizedLink from "../../components/LocalizedLink";
import axios from "axios";

import { IPageContext, IPrismicBookDemo } from "../prismic.model";
import * as styles from "./BookDemo.module.scss";
import { validateEmail, validatePhone } from "../../utils/constants";
import TagManager from "react-gtm-module";

interface IProps {
  data: IPrismicBookDemo;
  pageContext: IPageContext;
}

interface IState {
  isMailSent: boolean;
  mailFailed: boolean;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  demoReason: string;
  numberOfPlannedHires: string;
  message: string;
  firstNameError: boolean;
  lastNameError: boolean;
  emailError: boolean;
  phoneNumberError: boolean;
  demoReasonError: boolean;
  numberOfPlannedHiresError: boolean;
}

export default class BookDemo extends Component<IProps, IState> {
  state = {
    isMailSent: false,
    mailFailed: false,
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    demoReason: "",
    numberOfPlannedHires: "",
    message: "",
    firstNameError: false,
    lastNameError: false,
    emailError: false,
    phoneNumberError: false,
    demoReasonError: false,
    numberOfPlannedHiresError: false,
  };

  componentDidMount() {
    appInsights.trackPageView();
  }

  componentWillUnmount() {
    this.setState({
      isMailSent: false,
      mailFailed: false,
    });
  }

  render() {
    const { data } = this.props;
    const {
      isMailSent,
      firstName,
      lastName,
      email,
      phoneNumber,
      demoReason,
      numberOfPlannedHires,
      message,
      mailFailed,
      firstNameError,
      lastNameError,
      emailError,
      phoneNumberError,
      demoReasonError,
      numberOfPlannedHiresError,
    } = this.state;

    return (
      <div className={styles.container}>
        {isMailSent || mailFailed ? (
          <div className={styles.card}>
            <h2>
              {mailFailed ? data.error_title.text : data.success_title.text}
            </h2>
            <div className={styles.description}>
              {mailFailed
                ? data.error_description.text
                : data.success_description.text}
            </div>

            {mailFailed ? (
              <button className={styles.button} onClick={this.tryAgain}>
                {data.error_button.text}
              </button>
            ) : (
              <button className={styles.button}>
                <LocalizedLink to="">{data.success_button.text}</LocalizedLink>
              </button>
            )}
          </div>
        ) : (
          <div>
            <img className={styles.headerImage} src={data.header_image.url} />
            <h2>{data.title.text}</h2>
            <div className={styles.description}>{data.description.text}</div>

            <form className={styles.form} onSubmit={this.onSubmit}>
              <div className={styles.inputRow}>
                <div className={styles.inputContainer}>
                  <div
                    className={`${styles.label} ${
                      firstNameError && styles.labelError
                    }`}
                  >
                    {data.first_name_label.text}*
                  </div>
                  <input
                    type="text"
                    className={`${styles.input} ${
                      firstNameError && styles.error
                    }`}
                    name="firstName"
                    value={firstName}
                    onChange={this.onInputChange}
                  />
                </div>

                <div className={styles.inputContainer}>
                  <div
                    className={`${styles.label} ${
                      lastNameError && styles.labelError
                    }`}
                  >
                    {data.last_name_label.text}*
                  </div>
                  <input
                    type="text"
                    className={`${styles.input} ${
                      lastNameError && styles.error
                    }`}
                    name="lastName"
                    value={lastName}
                    onChange={this.onInputChange}
                  />
                </div>
              </div>

              <div className={styles.inputRow}>
                <div className={styles.inputContainer}>
                  <div
                    className={`${styles.label} ${
                      emailError && styles.labelError
                    }`}
                  >
                    {data.email_label.text}*
                  </div>
                  <input
                    type="text"
                    className={`${styles.input} ${emailError && styles.error}`}
                    name="email"
                    value={email}
                    onChange={this.onInputChange}
                  />
                </div>

                <div className={styles.inputContainer}>
                  <div
                    className={`${styles.label} ${
                      phoneNumberError && styles.labelError
                    }`}
                  >
                    {data.phone_number_label.text}*
                  </div>
                  <input
                    type="text"
                    className={`${styles.input} ${
                      phoneNumberError && styles.error
                    }`}
                    name="phoneNumber"
                    value={phoneNumber}
                    onChange={this.onInputChange}
                  />
                </div>
              </div>

              <div className={styles.inputRow}>
                <div className={styles.inputContainer}>
                  <div
                    className={`${styles.label} ${
                      demoReasonError && styles.labelError
                    }`}
                  >
                    {data.reason_label.text}*
                  </div>
                  <input
                    type="text"
                    className={`${styles.input} ${
                      demoReasonError && styles.error
                    }`}
                    name="demoReason"
                    value={demoReason}
                    onChange={this.onInputChange}
                  />
                </div>

                <div className={styles.inputContainer}>
                  <div
                    className={`${styles.label} ${
                      numberOfPlannedHiresError && styles.labelError
                    }`}
                  >
                    {data.hires_label.text}*
                  </div>
                  <input
                    type="number"
                    className={`${styles.input} ${
                      numberOfPlannedHiresError && styles.error
                    }`}
                    name="numberOfPlannedHires"
                    value={numberOfPlannedHires}
                    onChange={this.onInputChange}
                  />
                </div>
              </div>

              <div className={styles.inputRow}>
                <div className={styles.textAreaContainer}>
                  <div className={styles.label}>{data.message_label.text}</div>
                  <textarea
                    className={styles.textArea}
                    name="message"
                    value={message}
                    rows={5}
                    onChange={this.onInputChange}
                  />
                  <p className={styles.messageSubtitle}>
                    {data.message_subtitle.text}
                  </p>
                </div>
              </div>

              <button className={styles.button} type="submit">
                {data.submit_button.text}
              </button>
            </form>
          </div>
        )}
      </div>
    );
  }

  private onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      demoReason,
      numberOfPlannedHires,
      message,
    } = this.state;

    const firstNameError = firstName.length === 0 || !firstName.trim();
    const lastNameError = lastName.length === 0 || !lastName.trim();
    const demoReasonError = demoReason.length === 0 || !demoReason.trim();
    const numberOfPlannedHiresError =
      numberOfPlannedHires.length === 0 || !numberOfPlannedHires.trim();
    const phoneNumberError = !validatePhone(phoneNumber);
    const emailError = !validateEmail(email);

    if (
      firstNameError ||
      lastNameError ||
      emailError ||
      phoneNumberError ||
      demoReasonError ||
      numberOfPlannedHiresError
    ) {
      this.setState({
        firstNameError,
        lastNameError,
        emailError,
        phoneNumberError,
        demoReasonError,
        numberOfPlannedHiresError,
      });
    } else {
      axios
        .post(`${process.env.BASE_URL}/api/contactUs/demo`, {
          firstName,
          lastName,
          email,
          phoneNumber,
          demoReason,
          numberOfPlannedHires,
          message,
        })
        .then((response) => {
          if (response.data.isSuccess) {
            this.setState({
              isMailSent: true,
              mailFailed: false,
            });

            const event = {
              dataLayer: {
                event: "ga.bookDemo",
                eventProps: {
                  category: "User Action",
                  action: "Requested demo",
                  userEmail: email,
                  userName: firstName,
                },
              },
            };

            TagManager.dataLayer(event);
          } else {
            this.mailFailed();
          }
        })
        .catch(() => {
          this.mailFailed();
        });
    }
  };

  private tryAgain = () => {
    this.setState({
      mailFailed: false,
    });
  };

  private mailFailed = () => {
    this.setState({
      isMailSent: false,
      mailFailed: true,
    });
  };

  private onInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({
      [name]: value,
      [`${name}Error`]: false,
    } as ComponentState);
  };
}
